import {
  APP_INITIALIZER,
  ApplicationConfig,
  ErrorHandler,
  importProvidersFrom,
  Injectable,
} from "@angular/core";

import { DOCUMENT } from "@angular/common";
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
} from "@angular/common/http";
import { provideAnimations } from "@angular/platform-browser/animations";
import { Router, RouterModule } from "@angular/router";
import { ServiceWorkerModule } from "@angular/service-worker";
import { JwtModule } from "@auth0/angular-jwt";
import { environment } from "@env/environment";
import { DialogModule } from "@ngneat/dialog";
import {
  provideTransloco,
  Translation,
  TRANSLOCO_CONFIG,
  translocoConfig,
  TranslocoLoader,
} from "@ngneat/transloco";
import { TranslocoMessageFormatModule } from "@ngneat/transloco-messageformat";
import { EffectsModule } from "@ngrx/effects";
import { provideRouterStore } from "@ngrx/router-store";
import { StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import * as Sentry from "@sentry/angular";
import { BrowserOptions, browserTracingIntegration } from "@sentry/angular";
import { Integration } from "@sentry/types";
import amplitude from "amplitude-js";
import { QuicklinkStrategy } from "ngx-quicklink";
import { provideToastr } from "ngx-toastr";
import { routes } from "./app.routes";
import {
  AcceptHeaderInterceptor,
  ErrorMessagesInterceptor,
} from "./core/interceptors";
import { CleanHttpParamsInterceptor } from "./core/interceptors/clean-http-params.interceptor";
import { ErrorInterceptor } from "./core/interceptors/error.interceptor";
import { tokenGetter } from "./core/services/auth.service";
import { effects, metaReducers, ROOT_REDUCERS } from "./store";
import * as fromRouter from "./store/reducers/router.reducer";

amplitude.getInstance().init(environment.apmlitude, {
  autocapture: { elementInteractions: true },
});

const integrations: Integration[] = [
  browserTracingIntegration({
    tracingOrigins: [
      environment.primaryApiUrl,
      environment.patientProfileDomain,
    ],
  }),
];

const sentryConfig: BrowserOptions = {
  dsn: environment.sentry as any,
  environment: environment.name,
  integrations,
  tracesSampleRate: 1.0,
};

if (environment.production) {
  integrations.push(Sentry.replayIntegration());

  sentryConfig.replaysSessionSampleRate = 0.1;
  sentryConfig.replaysOnErrorSampleRate = 1.0;
}

Sentry.init(sentryConfig);

@Injectable({ providedIn: "root" })
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private http: HttpClient) {}

  getTranslation(lang: string) {
    return this.http.get<Translation>(`/assets/i18n/${lang}.json`);
  }
}

export const applicationConfig: ApplicationConfig = {
  providers: [
    // !Global DOCUMENT
    {
      provide: Document,
      useExisting: DOCUMENT,
    },
    // !HTTP_INTERCEPTORS
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: ResponseInterceptor,
    //   multi: true,
    // },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CleanHttpParamsInterceptor,
      multi: true,
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        availableLangs: environment.locales,
        defaultLang: environment.defaultLocale,
        fallbackLang: environment.fallbackLang,
        reRenderOnLangChange: true,
        prodMode: environment.production,
        missingHandler: {
          logMissingKey: false,
        },
      }),
    },
    provideTransloco({
      config: {
        availableLangs: ["ru", "en"],
        defaultLang: "ru",
        reRenderOnLangChange: true,
        prodMode: environment.production,
      },
      loader: TranslocoHttpLoader,
    }),
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    // Ngrx
    importProvidersFrom(
      StoreModule.forRoot(ROOT_REDUCERS, {
        metaReducers,
        runtimeChecks: {
          strictStateImmutability: true,
          strictActionImmutability: true,
          strictStateSerializability: true,
          strictActionSerializability: true,
          strictActionWithinNgZone: true,
          strictActionTypeUniqueness: true,
        },
      }),
      EffectsModule.forRoot(effects),

      StoreDevtoolsModule.instrument({
        name: "NGRX Nutrient Planner Store App",
        maxAge: 25,
        logOnly: environment.production,
        connectInZone: true,
      }),
    ),
    provideRouterStore({
      stateKey: fromRouter.routerFeatureKey,
      serializer: fromRouter.CustomSerializer,
    }),
    provideToastr({
      positionClass: "toast-bottom-right",
      preventDuplicates: true,
    }),
    importProvidersFrom(
      JwtModule.forRoot({
        config: {
          tokenGetter,
          allowedDomains: [environment.primaryApiUrl, environment.apiDomain],
          disallowedRoutes: [
            `${environment.primaryApiUrl}/auth/`,
            `${environment.primaryApiUrl}/profile/`,
            `https://${environment.primaryApiUrl}/patient/questionnaire/login`,
            `https://${environment.primaryApiUrl}/patient/questionnaire/patient`,
            `https://${environment.primaryApiUrl}/patient/questionnaire/update`,
          ],
          authScheme: "bearer ",
          skipWhenExpired: true,
        },
      }),
    ),
    importProvidersFrom(DialogModule.forRoot()),
    importProvidersFrom(TranslocoMessageFormatModule.forRoot()),
    importProvidersFrom(
      ServiceWorkerModule.register("ngsw-worker.js", {
        enabled: environment.production,
      }),
    ),
    importProvidersFrom(
      RouterModule.forRoot(routes, {
        // enableTracing: true,
        scrollPositionRestoration: "enabled",
        preloadingStrategy: QuicklinkStrategy,
      }),
    ),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorMessagesInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AcceptHeaderInterceptor,
      multi: true,
    },
    // !Sentry
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        logErrors: true,
        showDialog: false,
      }),
    },
  ],
};
